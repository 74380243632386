import React from 'react';
import config from '../../aws-exports';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  Loading,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact
} from 'aws-amplify-react';
import { signUpConfig, usernameAttributes } from '../../configs/signUpConfig';
import CustomSignIn from '../custom-pages/CustomSignIn';
import  CustomSignUp  from '../custom-pages/CustomSignUp';
import  CustomConfirmSignUp  from '../custom-pages/CustomConfirmSignUp';
import  CustomForgotPassword  from '../custom-pages/CustomForgotPassword';
import { getRedirectUrl, createCookie, clearCookie} from '../../util/util';
import { Auth } from 'aws-amplify';

export default class Authentication extends React.Component {
  constructor() {
    super();
    this.state = {
      authState: '',
      page: window.location.href.indexOf('signUp') > -1 ? 'signUp' : ''
    };
  }

  redirectToState = () => {
    switch (this.props.page) {
      case 'renew' :
        return 'forgotPassword';
      case 'signUp' :
        return 'signUp';
      default:
        return 'signIn'
    }
  };

  async isAuth() {
    try {
      const response = await Auth.currentSession();
      createCookie(response);

      this.setState({
        authState: 'signedIn'
      })
    }
    catch (e) {
      clearCookie();
      this.setState({
        authState: 'signOut'
      })
    }
  }

  onStateChange = async (authState) => {
    this.setState({ authState });
    await this.isAuth();

    if (authState === 'signedIn') {
      getRedirectUrl()
    }
  };

  render() {
    const { authState} = this.props;
    return (
      <div>
      <div className="banner-login"></div>           
                
          {authState !== 'signedIn' ? (
            <div className="row">

            </div>
          ) : (
            <div className="force-align-center">
              <div className="spinner"/>
            </div>
          )}
          <Authenticator
            authState={this.redirectToState()}
            amplifyConfig={config}
            onStateChange={this.onStateChange}
            usernameAttributes={usernameAttributes}            
            hide={[
              SignIn,
              ConfirmSignIn,
              RequireNewPassword,
              SignUp,
              Loading,
              ConfirmSignUp,
              VerifyContact,
              ForgotPassword,
            ]}
            hideDefault={true}
          >
           
            <CustomSignIn/>
            <ConfirmSignIn/>
            <RequireNewPassword/>
            <CustomSignUp override="SignUp" signUpConfig={signUpConfig}/>  
            <Loading/>  
            <CustomConfirmSignUp/>    
            <VerifyContact/>
            <CustomForgotPassword/>
          </Authenticator>
                 
       
    </div>   

    )
  }
}
