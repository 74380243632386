import Cookie from "js-cookie";

export const createCookie = (response) => {
  if (!response || !response.hasOwnProperty("idToken")) {
    redirectToLogin();
  }
  if (window.location.origin.indexOf("localhost")) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken);
  }
  Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const clearCookie = () => {
  localStorage.clear();
  if (window.location.origin.indexOf("localhost")) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }
  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const hasAdmin = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("adm") === "true";
};

export const redirectTo = (redirect = "") => {
  if (redirect) {
    window.location.href = redirect;
  } else if (hasAdmin()) {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`;
  } else {
    window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
  }
};

export const redirectToLogin = (redirect = "") => {
  if (redirect) {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(redirect)}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(redirect)}`;
    }
  } else {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_ADMIN_URL}`
      )}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_ACADEMY_URL}`
      )}`;
    }
  }
};

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const routes = ["signUp", "renew", "logout"];

  routes.forEach((route) => {
    if (params.get(route) !== null) {
      redirectTo(atob(params.get(route)));
    }
  });
  redirectTo("");
};

export const validPassword = (password) => {
  let valid = false;
  let message = "";

  if (
    password.length < 8 ||
    password.length > 24 ||
    !password.match(/[a-z]/) ||
    !password.match(/[A-Z]/) ||
    !password.match(/[@$!%*#?&.<>]/)
  ) {
    message =
      "A senha deve conter 8 caracteres, incluindo 1 letra maiúscula, 1 letra minúscula, números, e caractere especial (*&,.!)";
  } else {
    valid = true;
  }

  return { valid, message };
};
