/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { I18n } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import logo from "../../assets/images/logo.png";
import { settings } from "../../configs/settings";
import { validPassword } from "../../util/util";

export class CustomForgotPassword extends ForgotPassword {
  showComponent() {
    const submitForm = (e) => {
      if (e) {
        e.preventDefault();
      }

      let pwd = document.getElementsByName("password")[0].value;
      let error = document.getElementById("error");

      const password = validPassword(pwd);

      if (password.valid) {
        if (error) {
          error.innerHTML = "";
        }

        return super.submit();
      }

      error.innerHTML = password.message;
      return false;
    };

    return (
      <div className="cardMiddle">
        <div className="row">
          <div className="col-6 image-align">
            <div className="card-image">
              <div>
                <div className="title-logo">
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className="img-fluid logo"
                  />
                  <div className="text-image">
                    {I18n.get("Have registration?")}
                  </div>
                  <button
                    className="btn-create"
                    type="button"
                    onClick={() => super.changeState("signIn")}
                  >
                    {I18n.get("Back to Sign In")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 card-forgotpassword">
            {this.state.delivery ? (
              <>
                {this.submitView()}
                <div id="error" className="error forgot" />
              </>
            ) : (
              this.sendView()
            )}
            <div className="center">
              {this.state.delivery ? (
                <button className="btn-signin" onClick={() => submitForm()}>
                  {I18n.get("Submit")}
                </button>
              ) : (
                <button className="btn-signin" onClick={this.send}>
                  {I18n.get("Submit")}
                </button>
              )}
              {this.state.delivery ? (
                <div>
                  <a className="resend-code" onClick={this.send}>
                    {I18n.get("Resend Code")}
                  </a>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomForgotPassword;
