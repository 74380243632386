import React from "react";
import { Auth } from "aws-amplify";
import { SignUp } from "aws-amplify-react";
import { I18n, ConsoleLogger as Logger  } from "@aws-amplify/core";
import logo from "../../assets/images/logo.png";
import { settings } from "../../configs/settings";
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';

const logger = new Logger("SignUp");

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);
    this.signUpFields = this.props.signUpConfig.signUpFields;
    this.state = {
      password: 'password',
      confirmPassword: 'password',
      checked: false,
      valid: false,
    }
  }

  componentDidMount() {
    ReactPixel.init('224101575526074');
    TagManager.initialize('AW-742442251');
  }

  signUp() {
    if (!this.inputs.dial_code) {
      this.inputs.dial_code = this.getDefaultDialCode();
    }
    const validation = this.validate();
    if (validation && validation.length > 0) {
      return this.error(
        `The following fields need to be filled out: ${validation.join(", ")}`
      );
    }
    if (!Auth || typeof Auth.signUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    const signup_info = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: {},
    };

    const inputKeys = Object.keys(this.inputs);
    const inputVals = Object.values(this.inputs);

    inputKeys.forEach((key, index) => {
      if (
        !["username", "password", "checkedValue", "dial_code"].includes(key)
      ) {
        if (
          key !== "phone_line_number" &&
          key !== "dial_code" &&
          key !== "error"
        ) {
          const newKey = `${this.needPrefix(key) ? "custom:" : ""}${key}`;
          signup_info.attributes[newKey] = inputVals[index];
        }
      }
    });

    if (this.phone_number)
      signup_info.attributes["phone_number"] = this.phone_number;

    let labelCheck = false;
    this.signUpFields.forEach(field => {
      if (field.label === this.getUsernameLabel()) {
        logger.debug(`Changing the username to the value of ${field.label}`);
        signup_info.username =
          signup_info.attributes[field.key] || signup_info.username;
        labelCheck = true;
      }
    });
    if (!labelCheck && !signup_info.username) {
      throw new Error(
        `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`
      );
    }

    Auth.signUp(signup_info)
      .then(data => {
        ReactPixel.track('Lead');
        this.changeState('confirmSignUp', data.user.username)
        /*fetch(`${process.env.REACT_APP_API}/rd/contact`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:  JSON.stringify({
            name: this.inputs.name,
            email: this.inputs.email,
            tags: ["interessado"]
          })
        });
		    setTimeout(()=>{
          this.changeState('confirmSignUp', data.user.username)
        }, 3000)*/

      })
      .catch(err => this.error(err));

  }



  showHide(e) {
    if (this.state.password === 'input') {
      this.setState({
        password: 'password'
      })
    }
    else {
      this.setState({
        password: 'input'
      })
    }
  };

  showHideConfirmPassword(e) {
    if (this.state.confirmPassword === 'input') {
      this.setState({
        confirmPassword: 'password'
      })
    }
    else {
      this.setState({
        confirmPassword: 'input'
      })
    }
  };

  showComponent() {


    const SubmitForm = (e) => {

      let pwd = document.getElementsByName("password")[0].value;
      let pwd_confirm = document.getElementsByName("confirm_password")[0].value;
      let checkbox_confirm = document.getElementById("checkbox_terms").checked;

      let el = document.getElementById('flashDiv');
      if (el) {
        el.remove();
      }

      if (pwd === pwd_confirm && checkbox_confirm) {
        this.signUp()
      } else if(pwd !== pwd_confirm) {
        let flashDiv = document.createElement('div');
        flashDiv.id = 'flashDiv';
        document.getElementsByTagName('body')[0].appendChild(flashDiv);
        flashDiv.innerHTML = "Senhas estão diferentes";

      } else if(!checkbox_confirm){
        let flashDiv = document.createElement('div');
        flashDiv.id = 'flashDiv';
        document.getElementsByTagName('body')[0].appendChild(flashDiv);
        flashDiv.innerHTML = "É necessário concordar com os termos de uso";
      }
    };

    return (
      <div>
        <div className="cardMiddle">
          <div className="row">
            <div className="col-6 image-align">
              <div className="card-image">
                <div>
                  <div className="title-logo">
                    <img
                      src={logo}
                      alt={settings.title_site}
                      className="img-fluid logo"
                    />
                    <div className="text-image">
                      {I18n.get("Have registration?")}
                    </div>
                    <button className="btn-create" type="button" onClick={() => super.changeState("signIn")}>
                      {I18n.get("Back to Sign In")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
            <div className="card-signup">
              <form>
                <div className="title-signin center">
                  {I18n.get("Your registration")}
                </div>

                <input
                  className="input"
                  id="name"
                  key="name"
                  name="name"
                  type="text"
                  onChange={this.handleInputChange}
                  placeholder={I18n.get("Enter your username")}
                />

                <input
                  className="input"
                  id="email"
                  key="email"
                  name="email"
                  type="email"
                  onChange={this.handleInputChange}
                  placeholder={I18n.get("Enter your email")}
                />

                <input
                  className="input"
                  id="password"
                  key="password"
                  name="password"
                  type={this.state.password}
                  onChange={this.handleInputChange}
                  placeholder={I18n.get("Enter your password")}
                />
                <i
                  className={
                    this.state.password === "input" ? "far fa-eye pass-signup" : "far fa-eye-slash pass-signup"
                  }
                  onClick={(event) => this.showHide(event)}
                />
								<div className="description-password"> 
									A senha deve conter 8 caracteres, incluindo 1 letra maiúscula, 1 letra minúscula, números, e caractere especial (*&,.!) 
								</div>

                 <input
                  className="input"
                  id="confirm_password"
                  key="confirm_password"
                  name="confirm_password"
                  type={this.state.confirmPassword}
                  placeholder={I18n.get("Confirm Password")}
                />
                <i
                  className={
                    this.state.confirmPassword === "input"
                      ? "far fa-eye confirm-signup"
                      : "far fa-eye-slash confirm-signup"
                  }
                  onClick={(event) => this.showHideConfirmPassword(event)}
                />

                <div>
                  <label className="checkbox-label">
                    <input
                      className="checkbox"
                      id="checkbox_terms"
                      key="checkbox_terms"
                      value={this.state.checked}
                      onChange={() => this.setState({checked:!this.state.checked})}
                      name="checkbox_terms"
                      type="checkbox"
                    />
                    <span className="checkbox-custom"/>
                  </label>
                  <span className="text-checkbox">Eu aceito os</span> <a href={`http://www.b3.com.br/pt_br/termos-e-condicoes-de-uso/`} target="_blank" rel="noopener noreferrer" className="text-checkbox-terms">{I18n.get("Terms of use")}</a>
                </div>

                <div>
                  <label className="checkbox-label">
                    <input
                      className="checkbox"
                      id="checkbox_alerts"
                      key="checkbox_alerts"
                      name="checkbox_alerts"
                      type="checkbox"
                    />
                    <span className="checkbox-custom"/>
                  </label>
                  <span className="text-checkbox">{I18n.get("Email alerts")}</span>
                </div>

                <div className="center">
                  <button className="btn-signin" type="button" disabled={!this.state.checked} onClick={() => SubmitForm()}>
                    {I18n.get("Sign in")}
                  </button>
                </div>
              </form>
            </div>
          </div>

          </div>
        </div>
      </div>
    );
  };
}
export default CustomSignUp;
