/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:35d5f7e1-5719-498f-a9c5-99976fab3b84",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yIgxW7pKC",
    "aws_user_pools_web_client_id": "5ibpl9pk3rpjttjj3eq06ffb33",
    "oauth": {}
};


export default awsmobile;
