/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { I18n } from 'aws-amplify';
import { ConfirmSignUp } from 'aws-amplify-react';
import logo from '../../assets/images/logo.png';
import { settings } from '../../configs/settings';
import { auth } from '../../../node_modules/aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';

export class CustomConfirmSignUp extends ConfirmSignUp {

  showComponent() {

    return (

      <div className="cardMiddle">
        <div className="row">
          <div className="col-6 image-align">
            <div className="card-image">
              <div>
                <div className="title-logo">
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className="img-fluid logo"
                  />
                  <div className="text-image">
                    {I18n.get("Have registration?")}
                  </div>
                  <button className="btn-create" type="button" onClick={() => super.changeState("signIn")}>
                    {I18n.get("Back to Sign In")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="card-confirm">



              <div className="row">
                <div className="col-12">
                  <div className="ResetPassword text-left">

                    <div className="title-signin center">
                      {I18n.get("Confirm Sign Up")}
                    </div>

                    <input
                      className="input center"
                      key="username"
                      name="username"
                      value={this.usernameFromAuthData() ? this.usernameFromAuthData() : ''}
                      onChange={this.handleInputChange}
                      data-test={auth.confirmSignUp.usernameInput}
                      type="email"
                      placeholder={I18n.get("Enter your email")}
                    />

                    <input
                      className="input center"
                      key="code"
                      name="code"
                      onChange={this.handleInputChange}
                      data-test={auth.confirmSignUp.confirmationCodeInput}
                      autoComplete="off"
                      autoFocus
                      placeholder={I18n.get("Confirmation Code")}
                    />

                    <div className="center">
                      <button
                        className="btn-signin"
                        onClick={this.confirm}
                        data-test={auth.confirmSignUp.confirmButton}
                      >
                        {I18n.get('Submit')}
                      </button>
                    </div>

                    <div className="center">                      
                      <a  className="resend-code" onClick={() => this.resend}>
                        {I18n.get('Resend Code')}
                      </a>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

    );
  }
};
export default CustomConfirmSignUp;