/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SignIn } from "aws-amplify-react";
import { I18n } from "@aws-amplify/core";
import logo from '../../assets/images/logo.png';
import { settings } from '../../configs/settings';

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validauthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      type: 'password',
    }
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.type === 'input') {
      this.setState({
        type: 'password'
      })
    }
    else {
      this.setState({
        type: 'input'
      })
    }
  };

  showComponent(theme) {
    return (
      <div>
        <div className="cardMiddle">
          <div className="row">
            <div className="col-6 image-align">
              <div className="card-image">
                <div>
                  <div className="title-logo">
                    <img
                      src={logo}
                      alt={settings.title_site}
                      className="img-fluid logo"
                    />
                    <div className="text-image">{I18n.get('First Access?')}</div>
                    <button
                      className="btn-create"
                      type="button"                      
                      onClick={() => super.changeState("signUp")}>
                      {I18n.get('Create account')}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card-login">
                <form>
                  <div className="title-signin center">{I18n.get("Enter your login and password")}</div>
                  <input
                    className="input"
                    id="email"
                    key="email"
                    name="email"
                    type="email"
                    onChange={this.handleInputChange}
                    placeholder={I18n.get("Enter your email")}
                  />

                  <input
                    className="input"
                    id="password"
                    key="password"
                    name="password"
                    type={this.state.type}
                    onChange={this.handleInputChange}
                    placeholder={I18n.get("Enter your password")}
                  />
                  <i
                    className={this.state.type === 'input' ? 'far fa-eye' : 'far fa-eye-slash'} onClick={(event) => this.showHide(event)}></i>

                  <div className="center">
                    <button className="btn-signin" type="button" onClick={(event) => super.signIn(event)}>
                      {I18n.get('Sign in')}
                    </button>
                  </div>

                  <div className="center">
                    <a className="reset-password" onClick={() => super.changeState("forgotPassword")}>
                      {I18n.get("Reset password")}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  };
}

export default CustomSignIn;